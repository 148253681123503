import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import { useInView } from 'react-intersection-observer'

// Import Components
import { GtmDataLayerSetup, Layout, Map } from '@jelly/components'
import HeroBanner from 'components/HeroBanner'
import ProfileCard, { Name } from './_components/ProfileCard'
import { Link } from '@jelly/ui'
import aboutData from './_components/aboutData'

const LOCATION = {
	lat: 42.391389,
	lng: -71.256516,
	zoom: 11,
}

const AboutUsWrapper = styled.div`
	height: 100%;
	width: 100%;
`
const Button = styled(Link)`
	${({ theme }) => theme.buttons.default};
	margin: auto;
`
const BdcInfo = styled.div`
	line-height: 1.25;
	margin-bottom: 1rem;
	width: 100%;
	${({ theme }) => theme.MQ.M} {
		width: 45%;
	}
`
const CorporateInfoSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
`
const MapContainer = styled.div`
	display: inline-block;
	height: 15rem;
	justify-content: center;
	position: relative;
	width: 100%;
	${({ theme }) => theme.MQ.M} {
		vertical-align: middle;
		width: 50%;
	}
`
const Header = styled.h1`
	${({ theme }) => theme.header.h1};
	font-size: 34px;
	line-height: 50px;
	margin: 0;
	text-align: center;

	${({ theme }) => theme.BMQ.ML} {
		font-size: 45px;
	}

	${({ theme }) => theme.MQ.S} {
		line-height: normal;
	}

	${({ theme }) => theme.MQ.M} {
		font-size: 35px;
	}

	@media screen and (min-width: 833px) {
		${({ theme }) => theme.header.h1};
	}
`
const AboutContent = styled.div`
	font-size: 18px;
	padding-bottom: 5px;
`
const PageContent = styled.div`
	color: ${({ theme }) => theme.colors.darkGrey};
	display: block;
	margin: auto;
`
const SectionTitle = styled.h3`
	color: ${({ theme }) => theme.colors.navy};
	display: block;
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 2rem;
	text-align: center;
	${({ theme }) => theme.MQ.M} {
		text-align: left;
	}
`
const Section = styled.div`
	border-bottom: 2px solid #e4e4e4;
	padding: 1rem 0;
	margin: 2rem auto;
	${({ theme }) => theme.maxWidth.L};
	width: 90%;
	${({ theme }) => theme.BMQ.MXL} {
		margin: 1rem auto;
		padding: 1rem 0 2rem 0;
	}
	${({ theme }) => theme.MQ.XL} {
		margin: 3rem auto;
		padding: 1rem 1rem 2rem 1rem;
		width: 80%;
	}
`

const NoBorderSection = styled(Section)`
	border-bottom: none;
	display: block;
`
const ConnectWithUs = styled(SectionTitle)`
	margin: 0 auto 2rem auto;
	width: 100%;
	text-align: center;
	${({ theme }) => theme.MQ.M} {
		text-align: center;
	}
`
const HeadQuartersText = styled(Name)`
	margin-bottom: 15px;
`
const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colors.primary};
	display: inline-block;
	margin-bottom: 0.5rem;

	&:hover {
		color: ${({ theme }) => theme.colors.tertiary};
	}

	&:last-child {
		margin-bottom: 0;
	}
`

function AboutUsPage() {
	const { about, meta, staff } = aboutData

	const [mapRef, mapInView] = useInView({
		triggerOnce: true,
	})

	const {
		colors: { black },
	} = useTheme()
	return (
		<Layout isOverlappingHero={true} meta={meta}>
			<GtmDataLayerSetup contentType="about-us" templateType="" />
			<AboutUsWrapper>
				<HeroBanner
					align="center"
					background="https://www.business.com/images/about-us/about-us-hero.jpg"
					height="375px"
					bgPosition="70%"
					overlayColor={black}
					overlayOpacity="0.15"
					top="50%"
				>
					<Header> About Us </Header>
				</HeroBanner>
				<PageContent>
					{/* About */}
					<NoBorderSection>
						<SectionTitle>About Business News Daily</SectionTitle>
						<AboutContent dangerouslySetInnerHTML={{ __html: about }}></AboutContent>
					</NoBorderSection>
					{/* The Team */}
					<Section>
						<SectionTitle>The Business News Daily Team:</SectionTitle>
						{staff.map((params, i) => {
							return <ProfileCard key={`staff-${i}`} {...params} />
						})}
					</Section>
					<Section ref={mapRef}>
						{/* Corporate Location */}
						<CorporateInfoSection>
							<BdcInfo>
								<HeadQuartersText>Corporate&nbsp;Headquarters</HeadQuartersText>
								Business News Daily is headquartered in Waltham, Massachusetts, with additional offices
								in New York and Los Angeles.
								<br />
								<br />
								200 5th Avenue, Second Floor <br />
								Waltham, Massachusetts 02451 <br />
								<br />
								<StyledLink href="tel:888-393-5000">(888) 393-5000</StyledLink>
								<br />
								<StyledLink
									target="_blank"
									href="https://goo.gl/maps/x1txQvy53Dk"
									rel="noopener noreferrer"
								>
									Get Directions
								</StyledLink>
							</BdcInfo>
							<MapContainer>
								{mapInView && (
									<Map
										height="100%"
										latitude={LOCATION.lat}
										longitude={LOCATION.lng}
										zoom={LOCATION.zoom}
										width="100%"
									/>
								)}
							</MapContainer>
						</CorporateInfoSection>
					</Section>
					<NoBorderSection>
						{/* Contact Us */}
						<ConnectWithUs> We invite you to connect with us. </ConnectWithUs>
						<Button href="mailto:info@businessnewsdaily.com">Email Us</Button>
					</NoBorderSection>
				</PageContent>
			</AboutUsWrapper>
		</Layout>
	)
}

export default AboutUsPage
